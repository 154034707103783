import { useState, useEffect, useContext, useCallback, useRef } from "react";
import { MapContainer, TileLayer, Marker } from "react-leaflet";
import * as leaflet from "leaflet";
import classes from "./Map.module.css";
import AppContext from "../../../contexts/AppContext";
import MarkerPopup from "./MarkerPopup";
import mapVisited from "../../../assets/map_visited.svg";
import mapNotVisited from "../../../assets/map_not_visited.svg";

const mapCenter = [40.83, -73.88];

const notVisitedIcon = new leaflet.Icon({
    iconUrl: mapNotVisited,
    shadowUrl:
        "https://cdnjs.cloudflare.com/ajax/libs/leaflet/0.7.7/images/marker-shadow.png",
    iconSize: [30, 50],
    shadowAnchor: [5, 5],
    shadowSize: [16, 16],
    popupAnchor: [0, -12],
});

const visitedIcon = new leaflet.Icon({
    iconUrl: mapVisited,
    shadowUrl:
        "https://cdnjs.cloudflare.com/ajax/libs/leaflet/0.7.7/images/marker-shadow.png",
    iconSize: [30, 50],
    shadowAnchor: [5, 5],
    shadowSize: [16, 16],
    popupAnchor: [0, -12],
});

const largeNotVisitedIcon = new leaflet.Icon({
    iconUrl: mapNotVisited,
    shadowUrl:
        "https://cdnjs.cloudflare.com/ajax/libs/leaflet/0.7.7/images/marker-shadow.png",
    iconSize: [54, 90],
    shadowAnchor: [5, 5],
    shadowSize: [16, 16],
    popupAnchor: [0, -12],
});

const largeVisitedIcon = new leaflet.Icon({
    iconUrl: mapVisited,
    shadowUrl:
        "https://cdnjs.cloudflare.com/ajax/libs/leaflet/0.7.7/images/marker-shadow.png",
    iconSize: [54, 90],
    shadowAnchor: [5, 5],
    shadowSize: [16, 16],
    popupAnchor: [0, -12],
});

const Map = ({ currentView, setCurrentView, filter }) => {
    const ctx = useContext(AppContext);
    const markerRefs = useRef([]);
    const [marker, setMarker] = useState(null);
    const [markers, setMarkers] = useState([]);

    const getOccurrences = useCallback(() => {
        if (ctx.record["📍 Drop off location (from 📅 Scheduled Slots)"]) {
            const occ = {};
            for (const id of ctx.record[
                "📍 Drop off location (from 📅 Scheduled Slots)"
            ]) {
                occ[id] = occ[id] ? occ[id] + 1 : 1;
            }
            return occ;
        }
        return {};
    }, [ctx.record]);

    const getLastVisits = useCallback(() => {
        let lastVisits = {};
        ctx.visits.forEach((visit) => {
            const time = visit["Slot Time"] || visit["Event Start Time"];
            if (time !== undefined) {
                const date = new Date(time[0]);

                // Iterate over all locations if there are multiple
                visit["📍 Drop off location"]?.forEach((location) => {
                    if (
                        lastVisits[location] === undefined ||
                        date > lastVisits[location]
                    ) {
                        lastVisits[location] = date;
                    }
                });
            }
        });
        return lastVisits;
    }, [ctx.visits]);

    const createMarkers = useCallback(() => {
        let markerElems = [];

        const occurences = getOccurrences();
        const lastVisits = getLastVisits();

        for (const [index, location] of ctx.locations.entries()) {
            const id = location["LocationRecordID"];
            if (
                (filter === "visited" && !occurences[id]) ||
                (filter === "unvisited" && occurences[id])
            ) {
                continue;
            }

            const currentView = {
                name: location["Drop off location"],
                address: location["Drop-off Address"].split(",")[0],
                borough: location["Borough"],
                visits: occurences[id] || 0,
                lastVisit: lastVisits[id]?.toLocaleDateString("en-US"),
            };

            markerElems.push(
                <Marker
                    key={id}
                    ref={(e) => (markerRefs.current[index] = e)}
                    eventHandlers={{
                        click: () => setCurrentView(currentView),
                        popupopen: (popup) => {
                            setMarker(popup.target);
                            if (markerRefs && markerRefs.current[index]) {
                                markerRefs.current[index].setIcon(
                                    occurences[id]
                                        ? largeVisitedIcon
                                        : largeNotVisitedIcon,
                                );
                                markerRefs.current[index].setZIndexOffset(100);
                            }
                        },
                        popupclose: () => {
                            setCurrentView({
                                borough: currentView.borough,
                            });
                            if (markerRefs && markerRefs.current[index]) {
                                markerRefs.current[index].setIcon(
                                    occurences[id]
                                        ? visitedIcon
                                        : notVisitedIcon,
                                );
                                markerRefs.current[index].setZIndexOffset(0);
                            }
                        },
                    }}
                    icon={occurences[id] ? visitedIcon : notVisitedIcon}
                    position={[location.Latitude, location.Longitude]}
                >
                    <MarkerPopup
                        location={currentView.name}
                        address={currentView.address}
                        timesVisited={currentView.visits}
                    />
                </Marker>,
            );
        }

        setMarkers(markerElems);
    }, [ctx, filter, getOccurrences, getLastVisits, setCurrentView]);

    useEffect(() => {
        if (marker && !currentView.name) {
            marker.closePopup();
        }
    }, [marker, currentView]);

    useEffect(() => {
        if (ctx && Object.keys(ctx.record).length !== 0) {
            createMarkers();
        }
    }, [ctx, createMarkers]);

    return (
        <div className={classes.container}>
            <div className={classes.background}>
                {ctx.isLoading && (
                    <div className={classes.overlay}>
                        <p className={classes.loading}>Loading Markers</p>
                    </div>
                )}
                <div className={classes.legend}>
                    <div className={classes.visited}> = Visited</div>
                    <div className={classes.not_visited}> = Not Visited</div>
                </div>
                <MapContainer
                    className={classes.map_container}
                    center={mapCenter}
                    zoom={11.5}
                    minZoom={10.5}
                    zoomControl={false}
                >
                    <TileLayer
                        attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors &copy; <a href="https://carto.com/attributions">CARTO</a>'
                        url="https://{s}.basemaps.cartocdn.com/rastertiles/voyager/{z}/{x}/{y}{r}.png"
                    />
                    {markers}
                </MapContainer>
            </div>
        </div>
    );
};

export default Map;
