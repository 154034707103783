import classes from "./Overlay.module.css";

const Overlay = () => {
    return (
        <div className={classes.wrapper}>
            <div className={classes.spinner}>
                {/* CSS selectors operate on these empty divs.*/}
                <div />
                <div />
                <div />
                <div />
            </div>
            <p>Loading Content!</p>
        </div>
    );
};

export default Overlay;
