import classes from "./AboutPage.module.css";
import Star from "../../assets/star.svg";

const AboutPage = () => {
    return (
        <div className={classes.container}>
            <h1>About Our Volunteer Engagement App</h1>
            <p>
                Welcome to our Volunteer Engagement App, a tool specially
                designed to engage and reward the amazing volunteers who support
                our food rescue nonprofit in New York City.
            </p>
            <h1>What is this app?</h1>
            <p>
                This app is a platform that serves to engage volunteers,
                offering a rewarding and gamified experience for your selfless
                contribution to ending food insecurity in New York City. We aim
                to enhance volunteer participation and retention by offering
                incentives, making your volunteering journey more enjoyable and
                fulfilling.
            </p>
            <h1>What is the map for?</h1>
            <p>
                The map is a tool to see all of the locations that you have
                visited and have yet to visit as a Grassroots Grocery Volunteer.
            </p>
            <div>
                <h1>What is the star icon?</h1>
                <img src={Star} alt="star" />
            </div>
            <p>
                The star icon represents the 'stars' or points that you earn as
                a volunteer. The more volunteer tasks you complete, the more
                stars you earn. Stars indicate your level of participation and
                progress in our volunteering program.
            </p>
            <h1>How does the badge system work?</h1>
            <p>
                There are eight consecutive badges that can be earned. Each
                badge is progressively more difficult to earn but also signifies
                a greater contribution to Grassroots Groceries. As you earn
                stars, you'll level up to earn these badges. Each badge comes
                with its own unique icon and represents a significant
                accomplishment within our community. Earning badges not only
                showcases your commitment and hard work but also helps you track
                your impact and progress in fighting food insecurity.
            </p>
            <h1>How can I earn more stars?</h1>
            <p>
                Volunteering! Participating in our volunteering events will earn
                you stars. There are also challenges that can be completed which
                will earn you bonus stars. These challenges can be found on the
                badge progression page and include visiting certain locations,
                maintaining volunteering streaks, and placing on the
                leaderboard.
            </p>
            <h1>Can I redeem my stars for rewards?</h1>
            <p>
                Currently, the stars serve as a marker of your volunteer
                progression and cannot be redeemed for physical rewards.
                However, the badge and star system serves as a recognition of
                your hard work and dedication to our cause. Every star earned
                and every badge unlocked signifies your invaluable contribution
                to our mission of eradicating hunger in New York City. Let's
                continue making a difference together!
            </p>
        </div>
    );
};

export default AboutPage;
