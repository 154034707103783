import { Popup } from "react-leaflet";
import addressSVG from "../../../assets/black_address.svg";
import markerSVG from "../../../assets/marker.svg";
import classes from "./MarkerPopup.module.css";

const MarkerPopup = ({ location, address, timesVisited }) => {
    return (
        <Popup className={classes.popup}>
            <div className={classes.container}>
                <p className={classes.location}>{location}</p>
                <p className={classes.address}>
                    <img src={addressSVG} alt="" />
                    {address}
                </p>
                <div className={classes.wrapper}>
                    <img src={markerSVG} alt="" />
                    {!timesVisited || timesVisited <= 0 ? (
                        <p className={classes.not_visited}>Not Visited</p>
                    ) : (
                        <p className={classes.visited}>
                            Visited {timesVisited}{" "}
                            {timesVisited === 1 ? "time" : "times"}
                        </p>
                    )}
                </div>
            </div>
        </Popup>
    );
};

export default MarkerPopup;
