import { useContext, useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import AuthContext from "../../contexts/AuthContext";
import classes from "./SigninEmailSentPage.module.css";
import Alert from "./Alert";
import BackButton from "../../assets/back_button.svg";
import GrassrootsLogo from "../../assets/grassroots_logo.svg";
import LoginTrim from "../../assets/login_trim.svg";

const SigninEmailSentPage = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const { authState, signinEmail } = useContext(AuthContext);

    // alert obj shape: {message: string, type: "success" | "error"}
    const [alert, setAlert] = useState(null);

    // If the user is already logged in, redirect to the dashboard
    useEffect(() => {
        if (authState === 1) navigate("/register");
        else if (authState === 2) navigate("/");
    }, [authState, navigate]);

    // If there is no email, redirect to the login page
    useEffect(() => {
        if (location.state === null || location.state.email === undefined) {
            navigate("/login");
        }
    }, [location.state, navigate]);

    const handleSendAgain = async () => {
        if (
            location.state.email !== undefined &&
            (await signinEmail(location.state.email))
        ) {
            setAlert({
                message: `Resent login link to ${location.state.email}.`,
                type: "success",
            });
        } else {
            setAlert({
                message: "Failed to resent email.",
                type: "error",
            });
        }
    };

    return (
        <div className={classes.container}>
            <div className={classes.card}>
                <div className={classes.header}>
                    <img
                        className={classes.back}
                        src={BackButton}
                        alt="back button"
                        onClick={() => navigate("/login")}
                    />
                    <img
                        className={classes.logo}
                        src={GrassrootsLogo}
                        alt="logo"
                    />
                </div>
                {alert !== null && (
                    <Alert
                        type={alert.type}
                        message={alert.message}
                        closeAlert={() => setAlert(null)}
                    />
                )}
                <div className={classes.wrapper}>
                    <div>
                        <p className={classes.title}>Login Email Sent</p>
                        <p className={classes.instructions}>
                            An email containing your login link has been sent to{" "}
                            {location.state && location.state.email}.
                        </p>
                    </div>
                    <button
                        className={classes.sendAgainBtn}
                        onClick={handleSendAgain}
                    >
                        Send Again
                    </button>
                </div>
            </div>
            <img src={LoginTrim} alt="footer" />
        </div>
    );
};

export default SigninEmailSentPage;
