import Card from "../components/ProgressionPage/Card";

// Datastructure format: [achievment_id, achievment_name, achievment_description]
// achievment_id -  is the property name in the user record
// achievment_name -  is the name of the achievment to be displayed
// achievment_description -  is the description of the achievment to be displayed

// The order of the elements matters.

export const EXPLORATION_ACHVS = ["ACHV_Exploration"];

export const STREAK_ACHVS = [
    ["ACHV_Streak3Weeks", "Events Attended", "3 Week Streak"],
    ["ACHV_Streak4Weeks", "Events Attended", "4 Week Streak"],
    ["ACHV_Streak5Weeks", "Events Attended", "5 Week Streak"],
    ["ACHV_Streak7Weeks", "Events Attended", "7 Week Streak"],
    ["ACHV_Streak10Weeks", "Events Attended", "10 Week Streak"],
    ["ACHV_Streak15Weeks", "Events Attended", "15 Week Streak"],
];

export const POINTS_ACHVS = [
    ["ACHV_PointsTop1", "All Time Ranking", "Champion"],
    ["ACHV_PointsTop5", "All Time Ranking", "Top Five"],
    ["ACHV_PointsTop10", "All Time Ranking", "Top Ten"],
    ["ACHV_Points1MonthTop1", "Past 30 Days Ranking", "Champion"],
    ["ACHV_Points1MonthTop5", "Past 30 Days Ranking", "Top Five"],
    ["ACHV_Points1MonthTop10", "Past 30 Days Ranking", "Top Ten"],
    ["ACHV_Points2WeeksTop1", "Past 2 Weeks Ranking", "Champion"],
    ["ACHV_Points2WeeksTop5", "Past 2 Weeks Ranking", "Top Five"],
    ["ACHV_Points2WeeksTop10", "Past 2 Weeks Ranking", "Top Ten"],
];

export const createCards = (achvs, user, type) => {
    let cardsProps = [];

    if (type === "exploration") {
        const locations = user[EXPLORATION_ACHVS[0]]?.split(",");
        if (!locations) {
            return <span>Explore locations to earn archievements!</span>;
        }

        cardsProps = locations.map((loc) => ({
            achvKey: loc,
            achvName: "Attend your first event",
            achvDesc: `at ${loc}`,
            achvAmt: 1,
        }));
    } else {
        cardsProps = achvs.map((achv) => {
            const [achvKey, achvName, achvDesc] = achv;
            const achvAmt = user[achvKey] ?? 0;
            return { achvKey, achvName, achvDesc, achvAmt };
        });
    }

    return cardsProps.map((card) => (
        <Card
            key={card.achvKey}
            name={card.achvName}
            text={card.achvDesc}
            points={50}
            multiple={card.achvAmt}
            complete={card.achvAmt > 0}
        />
    ));
};
