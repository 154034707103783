import { useContext, useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import classes from "./VerifyPhoneSignin.module.css";
import ReactInputVerificationCode from "react-input-verification-code";
import AuthContext from "../../contexts/AuthContext";
import BackButton from "../../assets/back_button.svg";
import GrassrootsLogo from "../../assets/grassroots_logo.svg";
import LoginTrim from "../../assets/login_trim.svg";

const VerifyPhoneSignin = () => {
    const location = useLocation();
    const navigate = useNavigate();

    const { authState, confirmPhoneSignIn } = useContext(AuthContext);

    const [verificationCode, setVerificationCode] = useState("");
    const [isInvalidCode, setIsInvalidCode] = useState(false);
    const [isSending, setIsSending] = useState(false);
    const [height, setHeight] = useState(window.innerHeight);

    const formattedPhone = ((x) => `(${x[1]}) ${x[2]}-${x[3]}`)(
        location.state.phone.match(/^(\d{3})(\d{3})(\d{4})$/),
    );

    // If the user is already logged in, redirect to the dashboard
    useEffect(() => {
        if (authState === 1) navigate("/register");
        else if (authState === 2) navigate("/");
    }, [authState, navigate]);

    useEffect(() => {
        // if there is no phone or confirmation, redirect to the login page
        if (
            location.state === null ||
            location.state.phone === undefined ||
            window.confirmationResult === undefined
        ) {
            navigate("/login");
        }
    }, [location.state, navigate]);

    useEffect(() => {
        const handleResize = () => {
            setHeight(window.innerHeight);
        };
        window.addEventListener("resize", handleResize);
        return () => {
            window.removeEventListener("resize", handleResize);
        };
    }, []);

    // handle logic for submitting verification code.
    const handleSubmit = async () => {
        setIsSending(true);
        confirmPhoneSignIn(verificationCode).then((resp) => {
            if (resp === false) setIsInvalidCode(true);
        });
        setIsSending(false);
    };

    return (
        <div className={classes.container} style={{ height: `${height}px` }}>
            <div className={classes.card}>
                <div className={classes.header}>
                    <img
                        className={classes.back}
                        src={BackButton}
                        alt="back button"
                        onClick={() => navigate("/login")}
                    />
                    <img
                        className={classes.logo}
                        src={GrassrootsLogo}
                        alt="logo"
                    />
                </div>
                <div className={classes.wrapper}>
                    <p className={classes.title}>Confirmation</p>
                    <p className={classes.instructions}>
                        Please enter the 6-digit code sent to&nbsp;
                        <span>{formattedPhone}</span>.
                    </p>
                    <div
                        className={`${classes.input} ${
                            isInvalidCode ? classes.invalid : ""
                        }`}
                    >
                        <ReactInputVerificationCode
                            autoFocus={true}
                            length={6}
                            placeholder="_"
                            onChange={(val) => {
                                if (isInvalidCode) setIsInvalidCode(false);
                                setVerificationCode(val);
                            }}
                            value={verificationCode}
                        />
                        {isInvalidCode && (
                            <p>The verification code is incorrect.</p>
                        )}
                    </div>
                    <button
                        className={isSending ? classes.sending : ""}
                        onClick={handleSubmit}
                    >
                        {isSending ? "Logging In..." : "Submit"}
                    </button>
                </div>
            </div>
            <img src={LoginTrim} alt="footer" />
        </div>
    );
};

export default VerifyPhoneSignin;
