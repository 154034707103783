import { useContext } from "react";
import AppContext from "../../contexts/AppContext";
import AuthContext from "../../contexts/AuthContext";
import classes from "./Profile.module.css";

const Profile = () => {
    const { record } = useContext(AppContext);
    const { signout } = useContext(AuthContext);

    return (
        <div className={classes.container}>
            <div className={classes.background}>
                <p className={classes.profile_text}>
                    {record["First Name"]
                        ? record["First Name"].charAt(0).toUpperCase()
                        : "Me"}
                </p>
            </div>
            <div className={classes.dropdown_content}>
                <button onClick={signout}>Logout</button>
            </div>
        </div>
    );
};

export default Profile;
