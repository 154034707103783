import classes from "./Card.module.css";
import Star from "../../assets/star.svg";

const Card = ({
    name = "",
    text = "",
    points = 0,
    multiple = 1,
    complete = true,
}) => {
    return (
        <div
            className={`${classes.container} ${
                !complete ? classes.disabled : ""
            }`}
        >
            <div className={classes.text}>
                <p>{name}</p>
                <p>{text}</p>
            </div>
            <div className={classes.points}>
                <img src={Star} alt="star" />
                <p>+{points}</p>
            </div>
            {multiple > 1 && (
                <div className={classes.multiple}>x{multiple}</div>
            )}
        </div>
    );
};

export default Card;
