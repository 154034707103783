import { useContext, useState } from "react";
import classes from "./MapContainer.module.css";
import AppContext from "../../contexts/AppContext";
import CurrentView from "./CurrentView/CurrentView";
import Map from "./Map/Map";
import HomeIcon from "../../assets/home_icon_black.svg";
import LocationIcon from "../../assets/location_icon_black.svg";
import getFavoriteLocations from "../../utils/getFavoriteLocations";

const MapContainer = () => {
    const ctx = useContext(AppContext);
    const [visited, location] = getFavoriteLocations(ctx);
    const [currentView, setCurrentView] = useState({});

    return (
        <div className={classes.container}>
            <CurrentView currentView={currentView} />
            <Map currentView={currentView} setCurrentView={setCurrentView} />
            <div className={classes.location_container}>
                <div className={classes.module}>
                    <img src={HomeIcon} alt="home icon" />
                    <div className={classes.text_container}>
                        <p className={classes.label}>Most Visited</p>
                        <p className={classes.info} title={visited}>
                            {ctx.isLoading ? "Loading..." : visited}
                        </p>
                    </div>
                </div>

                <div className={classes.module}>
                    <img src={LocationIcon} alt="location icon" />
                    <div className={classes.text_container}>
                        <p className={classes.label}>Favorite Location</p>
                        <p className={classes.info} title={location}>
                            {ctx.isLoading ? "Loading..." : location}
                        </p>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default MapContainer;
