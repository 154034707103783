import { useContext } from "react";
import AppContext from "../../contexts/AppContext";
import classes from "./ProgressionPage.module.css";
import Carousel from "./BadgeCarousel";
import {
    STREAK_ACHVS,
    POINTS_ACHVS,
    EXPLORATION_ACHVS,
    createCards,
} from "../../utils/achievments";
import Overlay from "../LoginPage/Overlay";

const ProgressionPage = () => {
    const { record: user, isLoading } = useContext(AppContext);

    if (isLoading) return <Overlay />;

    let streakCards = [];
    let pointCards = [];
    let explorationCards = [];

    if (user) {
        streakCards = createCards(STREAK_ACHVS, user, "streak");
        pointCards = createCards(POINTS_ACHVS, user, "point");
        explorationCards = createCards(EXPLORATION_ACHVS, user, "exploration");
    }

    // Add empty cards for flex wrap spacing
    const hidden = [...Array(4)].map((_, idx) => <div key={`hidden-${idx}`} />);

    return (
        <>
            <p className={classes.title}>Badge Progression</p>
            <div className={classes.container}>
                <Carousel />
                <p>Challenges</p>
                <div className={classes.subtitle}>
                    <p>Streaks</p>
                </div>
                <div className={classes.cards}>
                    {streakCards}
                    {hidden}
                </div>
                <div className={classes.subtitle}>
                    <p>Leaderboard (Awarded weekly)</p>
                </div>
                <div className={classes.cards}>
                    {pointCards}
                    {hidden}
                </div>
                <div className={classes.subtitle}>
                    <p>Exploration</p>
                </div>
                <div className={classes.cards}>
                    {explorationCards}
                    {hidden}
                </div>
            </div>
        </>
    );
};

export default ProgressionPage;
