import { createContext, useState, useCallback } from "react";
import Airtable from "airtable";

// the default values of properties that are exposed by the context.
const defaultValues = {
    record: {},
    visits: [],
    locations: [],
    neighborhoods: [],
    isLoading: true,
    setIsLoading: () => {},
    fetchData: () => ({}),
};

const AppContext = createContext(defaultValues);

export const AppContextProvider = (props) => {
    const [record, setRecord] = useState(defaultValues.record);
    const [visits, setVisits] = useState(defaultValues.visits);
    const [locations, setLocations] = useState(defaultValues.locations);
    const [neighborhoods, setNeighborhoods] = useState(
        defaultValues.neighborhoods,
    );
    const [isLoading, setIsLoading] = useState(defaultValues.isLoading);

    // Fetch data from Airtable
    const fetchData = useCallback(async (user) => {
        setIsLoading(true);

        let email = user.email;
        let phoneNumber = user.phoneNumber;

        // Development phone number
        if (phoneNumber === "+15555555555") {
            phoneNumber = process.env.REACT_APP_PHONE_NUMBER;
        }

        // Convert phone number to (XXX) XXX-XXXX format
        if (phoneNumber) {
            let match = phoneNumber.match(/^\+1(\d{3})(\d{3})(\d{4})$/);
            if (match) {
                phoneNumber = `(${match[1]}) ${match[2]}-${match[3]}`;
            }
        }

        // Connect to the Airtable
        let base = new Airtable({
            apiKey: process.env.REACT_APP_AIRTABLE_API_KEY,
        }).base(process.env.REACT_APP_AIRTABLE_BASE_ID);

        const filter = {
            filterByFormula: phoneNumber
                ? `{Phone Number}='${phoneNumber}'`
                : `{Email Address}='${email}'`,
        };

        // Create promises to fetch data from Airtable
        const users = base("🙋🏽Volunteers CRM").select(filter).firstPage();
        const visits = base("📅 Scheduled Slots").select(filter).all();
        const locations = base("📍 Drop off locations")
            .select({ view: "Regular Saturday Partners" })
            .all();
        const neighborhoods = base("🏡 Neighborhoods").select().all();

        // Wait for all promises to resolve
        return Promise.all([users, visits, locations, neighborhoods])
            .then((results) => {
                const [users, visits, locations, neighborhoods] = results.map(
                    (result) => result.map((record) => record.fields),
                );

                setRecord(users[0]);
                setVisits(visits);
                setLocations(locations);
                setNeighborhoods(neighborhoods);
                setIsLoading(false);

                return users[0] !== undefined;
            })
            .catch((err) => {
                console.error(err);
                return false;
            });
    }, []);

    return (
        <AppContext.Provider
            value={{
                record,
                locations,
                neighborhoods,
                visits,
                isLoading,
                setIsLoading,
                fetchData,
            }}
        >
            {props.children}
        </AppContext.Provider>
    );
};

export default AppContext;
