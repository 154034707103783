// Get visited and total locations in each borough
const getBoroughCounts = (appContext) => {
    let counts = {
        Bronx: [0, 0],
        Brooklyn: [0, 0],
        Manhattan: [0, 0],
        Queens: [0, 0],
        "Staten Island": [0, 0],
        All: [0, 0],
    };

    appContext.locations.forEach((location) => {
        if (!(location["LocationRecordID"])){
            console.log("LocationRecordID field failure");
            return;
        }
        if (!(location["Borough"])){
            console.log("Borough field failure");
            return;
        }
        
        let id = location["LocationRecordID"];
        let borough = location["Borough"];

        if (
            appContext.record[
                "📍 Drop off location (from 📅 Scheduled Slots)"
            ]?.includes(id)
        ) {
            counts[borough][0] += 1;
            counts["All"][0] += 1;
        }
        counts[borough][1] += 1;
        counts["All"][1] += 1;
    });

    return counts;
};

export default getBoroughCounts;
