import classes from "./RadialProgressBar.module.css";

const pi = 22 / 7;
const calcStrokeDasharray = (radius, percentFilled) =>
    `${radius * pi * percentFilled}, ${radius * pi}`;

const RadialProgressBar = ({
    numerator = 0,
    denominator = 1,
    size = 0,
    fontSize = 18,
    color = "#2e2d2b",
    strokeWidthInner = 4,
    strokeWidthOuter = 8,
}) => {
    return (
        <div>
            <div
                className={classes.dashboard}
                style={{ height: `${size}px`, width: `${size}px` }}
            >
                <svg>
                    <circle
                        className={classes.bg}
                        strokeWidth={strokeWidthInner}
                        cx={size / 2}
                        cy={size / 2}
                        r={size / 2 - 8}
                    />
                    {numerator > 0 && denominator > 0 ? (
                        <circle
                            className={classes.meter}
                            strokeDasharray={calcStrokeDasharray(
                                size - 16,
                                numerator / denominator,
                            )}
                            strokeWidth={strokeWidthOuter}
                            cx={size / 2}
                            cy={size / 2}
                            r={size / 2 - 8}
                        />
                    ) : (
                        <></>
                    )}
                </svg>
                <div className={classes.proportion} style={{ fontSize }}>
                    {numerator}/
                    <span style={{ color: color }}>{denominator}</span>
                </div>
            </div>
        </div>
    );
};

export default RadialProgressBar;
