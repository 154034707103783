import classes from "./Alert.module.css";

const Alert = ({ type, message, closeAlert }) => {
    return (
        <div className={`${classes.alert} ${classes[type]}`}>
            <span className={classes.close} onClick={closeAlert}>
                &times;
            </span>
            <div>{message}</div>
        </div>
    );
};

export default Alert;
