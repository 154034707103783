import { useContext, useEffect, useState, useRef } from "react";
import { useNavigate } from "react-router-dom";
import classes from "./MobileCurrentView.module.css";
import AppContext from "../../../contexts/AppContext";
import RadialProgressBar from "./RadialProgressBar";
import getBoroughCounts from "../../../utils/getBoroughCounts";
import grayAddress from "../../../assets/gray_address.svg";
import grayLine from "../../../assets/gray_line.svg";
import downArrow from "../../../assets/down_arrow.svg";
import leftArrow from "../../../assets/left_arrow.svg";
import whiteLink from "../../../assets/white_link.svg";
import fiftyPoints from "../../../assets/fifty_points.svg";

const MobileCurrentView = ({ currentView, setCurrentView }) => {
    const navigate = useNavigate();
    const ctx = useContext(AppContext);
    const ref = useRef();
    const [name, setName] = useState(undefined);
    const [position, setPosition] = useState({
        start: undefined,
        current: undefined,
        offset: 0,
    });

    const boroughCounts = getBoroughCounts(ctx);
    const address = currentView.address;
    const borough = currentView.borough || "Bronx";
    const visits = currentView.visits;
    const lastVisit = currentView.lastVisit;
    const maxTransform = name === undefined ? -144 : -254;

    // TODO: get from database
    const isStar = false;

    const handleTouchStart = (e) => {
        setPosition({
            ...position,
            start: e.changedTouches[0].screenY,
            current: e.changedTouches[0].screenY,
        });
    };

    const handleTouchMove = (e) => {
        let y = e.changedTouches[0].screenY;
        let transform = y - position.start + position.offset;
        if (transform >= maxTransform && transform <= 0) {
            setPosition({ ...position, current: y });
        }
        ref.current.style.transform = `translateY(${
            position.current - position.start + position.offset
        }px)`;
    };

    const handleTouchEnd = () => {
        if (position.start !== undefined && position.current !== undefined) {
            setPosition({
                start: undefined,
                current: undefined,
                offset: position.current - position.start + position.offset,
            });
        }
    };

    const handleBack = () => {
        setCurrentView({ borough: borough });
    };

    const handleChallengeOpen = () => {
        let offset = position.offset === maxTransform ? 0 : maxTransform;
        setPosition({ start: undefined, current: undefined, offset: offset });
        ref.current.style.transition = "transform 0.5s";
        ref.current.style.transform = `translateY(${offset}px)`;
        setTimeout(() => (ref.current.style.transition = ""), 500);
    };

    // Reset position when view changes
    useEffect(() => {
        ref.current.style.transform = `translateY(0px)`;
        setPosition({ start: undefined, current: undefined, offset: 0 });

        // Must occur after position is reset so useEffect doesn't trigger
        setName(currentView.name);
    }, [currentView]);

    useEffect(() => {
        if (
            position.start === undefined &&
            position.current === undefined &&
            position.offset !== 0 &&
            position.offset !== maxTransform
        ) {
            let offset = position.offset;
            if (offset > maxTransform / 2) {
                offset = Math.min(0, offset / 1.1 + 0.5);
            } else {
                offset = Math.max(
                    maxTransform,
                    maxTransform - (maxTransform - offset) / 1.1 - 0.5,
                );
            }

            const animationRef = requestAnimationFrame(() => {
                setPosition({ ...position, offset: offset });
                ref.current.style.transform = `translateY(${offset}px)`;
            });
            return () => cancelAnimationFrame(animationRef);
        }
    }, [position, maxTransform]);

    const radials = ["All", "Bronx", "Manhattan"]
        .filter((b) => b !== borough)
        .map((b) => (
            <div key={b} className={classes.progressBar}>
                <RadialProgressBar
                    numerator={boroughCounts[b][0]}
                    denominator={boroughCounts[b][1]}
                    size={80}
                />
                <p>{b}</p>
            </div>
        ));

    // Borough information
    const boroughView = (
        <>
            <div className={classes.borough}>
                <div>
                    <p className={classes.viewing}>Currently Viewing</p>
                    <p className={classes.name}>{borough}</p>
                </div>
                <RadialProgressBar
                    numerator={boroughCounts[borough][0]}
                    denominator={boroughCounts[borough][1]}
                    size={100}
                    fontSize={22}
                    strokeWidthInner={6}
                    strokeWidthOuter={12}
                />
            </div>
            <div className={classes.progress}>{radials}</div>
        </>
    );

    // Location information
    const locationView = (
        <>
            <button className={classes.back} onClick={handleBack}>
                <img src={leftArrow} alt="" />
                <p>Back</p>
            </button>
            <p className={classes.viewing}>Currently Viewing</p>
            <p className={classes.name}>{name}</p>
            <div className={classes.visits}>
                {visits > 0 ? (
                    <p>
                        {visits} {visits === 1 ? "Visit" : "Visits"}
                    </p>
                ) : (
                    <p className={classes.gray}>Unvisited</p>
                )}
                {isStar ? <p>Star Contributor</p> : <></>}
            </div>
            <div className={classes.address}>
                <img src={grayAddress} alt="" />
                <p>{address}</p>
            </div>
            <div className={classes.footer}>
                {visits > 0 ? <p>Last visit {lastVisit}</p> : <></>}
                <button onClick={(e) => handleChallengeOpen(e)}>
                    <p>Challenges</p>
                    <img src={downArrow} alt="" />
                </button>
            </div>
            <div className={classes.hidden}>
                <div className={classes.challenges}>
                    <img src={fiftyPoints} alt="" />
                    {visits > 0 ? (
                        <p>
                            Continue your <b>weekly streak</b> at {name}
                        </p>
                    ) : (
                        <p>
                            Visit {name} for the <b>first time</b>
                        </p>
                    )}
                </div>
                <button
                    className={classes.challengesButton}
                    onClick={() => navigate("/progression")}
                >
                    View All Challenges <img src={whiteLink} alt="" />
                </button>
            </div>
        </>
    );

    return (
        <div
            ref={ref}
            className={classes.container}
            style={{ bottom: maxTransform + "px" }}
            onTouchStart={handleTouchStart}
            onTouchMove={handleTouchMove}
            onTouchEnd={handleTouchEnd}
        >
            <img src={grayLine} alt="" />
            {name === undefined ? boroughView : locationView}
        </div>
    );
};

export default MobileCurrentView;
