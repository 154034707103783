import { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import classes from "./Dashboard.module.css";
import AppContext from "../../contexts/AppContext";
import AuthContext from "../../contexts/AuthContext";
import MapContainer from "../MapContainer/MapContainer";
import Badge from "../Badge/Badge";
import BadgeProgression from "./BadgeProgression";
import Leaderboard from "../Leaderboard/Leaderboard";
import MobileDashboard from "./MobileDashboard";
import Overview from "./Overview";
import LightningBolt from "../../assets/lightning_bolt.svg";
import Calendar from "../../assets/calendar.svg";
import HelpCircle from "../../assets/help_circle.svg";
import Overlay from "../LoginPage/Overlay";

const Dashboard = () => {
    const navigate = useNavigate();

    const { authState } = useContext(AuthContext);
    const { record, isLoading } = useContext(AppContext);
    const [mobileView, setMobileView] = useState(false);

    // If the user is not logged in, redirect to the login page
    useEffect(() => {
        if (!isLoading && authState !== 2) navigate("/login");
    }, [authState, isLoading, navigate]);

    useEffect(() => {
        updateDimensions();
        window.addEventListener("resize", updateDimensions);

        return () => window.removeEventListener("resize", updateDimensions);
    }, []);

    const updateDimensions = () => {
        const width = window.innerWidth;
        setMobileView(width <= 600);
    };

    if (isLoading) return <Overlay />;
    if (mobileView) return <MobileDashboard />;

    return (
        <>
            <div className={classes.header}>
                <div className={classes.left_header}>
                    <p>
                        Welcome back, <br /> {record["First Name"]}!
                    </p>
                    <div className={classes.buttons_section}>
                        <a href="https://www.grassrootsgrocery.org/join-us-saturday">
                            <button>
                                <img src={Calendar} alt="calendar" />
                                Register for an Event
                            </button>
                        </a>
                        <button onClick={() => navigate("/my-events")}>
                            <img src={Calendar} alt="calendar" />
                            My Events
                        </button>
                        <button onClick={() => navigate("/progression")}>
                            <img src={LightningBolt} alt="lightning bolt" />
                            View Challenges
                        </button>
                        <button onClick={() => navigate("/about")}>
                            <img src={HelpCircle} alt="help circle" />
                            What is the Volunteer Engagement Portal?
                        </button>
                    </div>
                </div>
                <div className={classes.badge_container}>
                    <div className={classes.badge_overview}>
                        <Badge />
                        <Overview />
                    </div>
                    <BadgeProgression />
                </div>
            </div>
            <MapContainer />
            <Leaderboard />
        </>
    );
};

export default Dashboard;
