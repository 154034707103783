import { useContext, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import classes from "./RegisterPage.module.css";
import AuthContext from "../../contexts/AuthContext";
import BackButton from "../../assets/back_button.svg";
import GrassrootsLogo from "../../assets/grassroots_logo.svg";
import LoginTrim from "../../assets/login_trim.svg";

const RegisterPage = () => {
    const navigate = useNavigate();
    const { authState, signout } = useContext(AuthContext);

    // If the user is already logged in, redirect to the dashboard
    useEffect(() => {
        if (authState === 2) {
            navigate("/");
        } else {
            signout();
        }
    }, [authState, navigate, signout]);

    return (
        <div className={classes.container}>
            <div className={classes.card}>
                <div className={classes.header}>
                    <img
                        className={classes.back}
                        src={BackButton}
                        alt="back button"
                        onClick={() => navigate("/login")}
                    />
                    <img
                        className={classes.logo}
                        src={GrassrootsLogo}
                        alt="logo"
                    />
                </div>
                <span className={classes.title}>Register</span>
                <p>
                    We're sorry, but we couldn't find your account. We'd love
                    for you to volunteer with us, though! Click the following
                    link to get started:
                </p>
                <a href="https://www.grassrootsgrocery.org/join-us-saturday">
                    Volunteer Sign Up
                </a>
                <p>Thank you for your interest!</p>
            </div>
            <img src={LoginTrim} alt="footer" />
        </div>
    );
};

export default RegisterPage;
