import { useContext } from "react";
import classes from "./Overview.module.css";
import AppContext from "../../contexts/AppContext";
import GrayStar from "../../assets/graystar.svg";
import Star from "../../assets/star.svg";

const Overview = () => {
    const { record } = useContext(AppContext);

    return (
        <div className={classes.container}>
            <div className={classes.overview}>
                <p>This Month</p>
                <div className={classes.points}>
                    <img src={Star} alt="star" />
                    <p className={classes.bold_points}>
                        {record["Points1Month"] ?? 0}
                    </p>
                </div>
                <p className={classes.bold_events}>
                    {record["Count of Monthly Events Completed"] ?? 0} events
                </p>
            </div>
            <div className={classes.overview}>
                <p>Total</p>
                <div className={classes.points}>
                    <img src={GrayStar} alt="gray star" />
                    <p>{record["Points"] ?? 0}</p>
                </div>
                <p>{record["Count of Events Completed"] ?? 0} events</p>
            </div>
        </div>
    );
};

export default Overview;
