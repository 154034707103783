import { useState, useContext, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import classes from "./MobileNavbar.module.css";
import AppContext from "../../contexts/AppContext";
import AuthContext from "../../contexts/AuthContext";
import Badge from "../Badge/Badge";
import about_icon from "../../assets/about_icon.svg";
import logo from "../../assets/grassroots_logo_green.svg";
import hamburger from "../../assets/hamburger.svg";
import home_icon from "../../assets/home_icon.svg";
import map_icon from "../../assets/map_icon.svg";
import leaderboard_icon from "../../assets/leaderboard_icon.svg";
import progress_icon from "../../assets/progress_icon.svg";
import x_button from "../../assets/x_button.svg";
import signout_icon from "../../assets/signout_icon.svg";

const MobileNavbar = () => {
    const navigate = useNavigate();
    const { record } = useContext(AppContext);
    const { signout } = useContext(AuthContext);
    const [toggleNav, setToggleNav] = useState(false);

    const toggleMenu = () => {
        setToggleNav(!toggleNav);
    };

    const handleClick = (page) => {
        toggleMenu();
        navigate(page);
    };

    useEffect(() => {
        toggleNav
            ? (document.body.style.overflow = "hidden")
            : (document.body.style.overflow = "scroll");
    }, [toggleNav]);

    return (
        <nav className={classes.nav}>
            <img
                className={classes.hamburger}
                src={hamburger}
                onClick={toggleMenu}
                alt="hamburger"
            />
            <img className={classes.logo} src={logo} alt="" />

            {toggleNav && (
                <div className={classes.overlay}>
                    <div className={classes.menu}>
                        <img src={x_button} onClick={toggleMenu} alt="exit" />
                        <div className={classes.profile}>
                            <Badge height={100} detail={false} />
                            <p className={classes.name}>
                                {record["Full Name"]}
                            </p>
                            <p className={classes.email}>
                                {record["Email Address"]}
                            </p>
                        </div>
                        <div
                            className={classes.menu_item}
                            onClick={() => handleClick("/")}
                        >
                            <img src={home_icon} alt="home" />
                            Home
                        </div>
                        <div
                            className={classes.menu_item}
                            onClick={() => handleClick("/map")}
                        >
                            <img src={map_icon} alt="map" />
                            Map
                        </div>
                        <div
                            className={classes.menu_item}
                            onClick={() => handleClick("/leaderboard")}
                        >
                            <img src={leaderboard_icon} alt="home" />
                            Leaderboard
                        </div>
                        <div
                            className={classes.menu_item}
                            onClick={() => handleClick("/progression")}
                        >
                            <img src={progress_icon} alt="progress" />
                            Progress
                        </div>
                        <div
                            className={classes.menu_item}
                            onClick={() => handleClick("/about")}
                        >
                            <img src={about_icon} alt="about" />
                            About
                        </div>
                        <div className={classes.signout} onClick={signout}>
                            Sign Out
                            <img src={signout_icon} alt="" />
                        </div>
                    </div>
                    <div className={classes.background} onClick={toggleMenu} />
                </div>
            )}
        </nav>
    );
};

export default MobileNavbar;
