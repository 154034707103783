import React, { useEffect } from "react";
import ReactDOM from "react-dom/client";
import { HashRouter, Routes, Route, useNavigate } from "react-router-dom";
import reportWebVitals from "./reportWebVitals";
import "./index.css";
import AppContext, { AppContextProvider } from "./contexts/AppContext";
import AuthContext, { AuthContextProvider } from "./contexts/AuthContext";
import AboutPage from "./components/AboutPage/AboutPage";
import ConfirmEmailSigninPage from "./components/LoginPage/ConfirmEmailSigninPage";
import Dashboard from "./components/Dashboard/Dashboard";
import LoginPage from "./components/LoginPage/LoginPage";
import MobileLeaderboard from "./components/Leaderboard/MobileLeaderboard";
import MobileMapContainer from "./components/MapContainer/MobileMapContainer";
import MobileNavbar from "./components/Navbar/MobileNavbar";
import MobileProgressionPage from "./components/ProgressionPage/MobileProgressionPage";
import Navbar from "./components/Navbar/Navbar";
import ProgressionPage from "./components/ProgressionPage/ProgressionPage";
import RegisterPage from "./components/LoginPage/RegisterPage";
import SigninEmailSentPage from "./components/LoginPage/SigninEmailSentPage";
import VerifyPhoneSignin from "./components/LoginPage/VerifyPhoneSignin";
import MyEventComponent from "./components/MyEventsPage/MyEvents";

// Prevent pinch zooming on mobile devices
document.addEventListener(
    "touchmove",
    function (event) {
        if (event.touches?.length > 1) {
            event.preventDefault();
        }
    },
    { passive: false },
);

const root = ReactDOM.createRoot(document.getElementById("root"));

const ViewportProvider = ({ children }) => {
    const [width, setWidth] = React.useState(window.innerWidth);
    const [height, setHeight] = React.useState(window.innerHeight);
    const handleWindowResize = () => {
        setWidth(window.innerWidth);
        setHeight(window.innerHeight);
    };

    React.useEffect(() => {
        window.addEventListener("resize", handleWindowResize);
        return () => window.removeEventListener("resize", handleWindowResize);
    }, []);

    return (
        <viewportContext.Provider value={{ width, height }}>
            {children}
        </viewportContext.Provider>
    );
};

const useViewport = () => {
    const { width, height } = React.useContext(viewportContext);
    return { width, height };
};

const NavbarComponent = () => {
    const { isLoading } = React.useContext(AppContext);
    const { authState } = React.useContext(AuthContext);
    const { width } = useViewport();
    const breakpoint = 600;

    if (isLoading || authState !== 2) return <></>;
    return width < breakpoint ? <MobileNavbar /> : <Navbar />;
};

const MapComponent = () => {
    const { isLoading } = React.useContext(AppContext);
    const { authState } = React.useContext(AuthContext);
    const { width } = useViewport();
    const navigate = useNavigate();

    useEffect(() => {
        if (!isLoading && (width > 600 || authState !== 2)) {
            navigate(authState === 2 ? "/" : "/login");
        }
    }, [width, isLoading, authState, navigate]);

    return <MobileMapContainer />;
};

const LeaderboardComponent = () => {
    const { isLoading } = React.useContext(AppContext);
    const { authState } = React.useContext(AuthContext);
    const { width } = useViewport();
    const navigate = useNavigate();

    useEffect(() => {
        if (!isLoading && (width > 600 || authState !== 2)) {
            navigate(authState === 2 ? "/" : "/login");
        }
    }, [width, isLoading, authState, navigate]);

    return <MobileLeaderboard />;
};

const ProgressionComponent = () => {
    const { isLoading } = React.useContext(AppContext);
    const { authState } = React.useContext(AuthContext);
    const { width } = useViewport();
    const navigate = useNavigate();

    useEffect(() => {
        if (!isLoading && authState !== 2) {
            navigate(authState === 2 ? "/" : "/login");
        }
    }, [width, isLoading, authState, navigate]);

    return width <= 600 ? <MobileProgressionPage /> : <ProgressionPage />;
};

const viewportContext = React.createContext({});
root.render(
    <React.StrictMode>
        <AppContextProvider>
            <AuthContextProvider>
                <HashRouter>
                    <ViewportProvider>
                        <NavbarComponent />

                        <Routes>
                            <Route path="/" element={<Dashboard />} />
                            <Route
                                path="/progression"
                                element={<ProgressionComponent />}
                            />
                            <Route path="/map" element={<MapComponent />} />
                            <Route
                                path="/leaderboard"
                                element={<LeaderboardComponent />}
                            />
                            <Route 
                                path="/my-events"
                                element = {<MyEventComponent />}
                            />
                            <Route path="/about" element={<AboutPage />} />
                            {/* Routes associated with authentication */}
                            <Route path="/login" element={<LoginPage />} />
                            <Route
                                path="/confirmEmailSignin"
                                element={<ConfirmEmailSigninPage />}
                            />
                            <Route
                                path="/verifyPhoneSignin"
                                element={<VerifyPhoneSignin />}
                            />
                            <Route
                                path="/signinEmailSent"
                                element={<SigninEmailSentPage />}
                            />
                            <Route
                                path="/register"
                                element={<RegisterPage />}
                            />
                            <Route path="/*" element={<Dashboard />} />
                        </Routes>
                    </ViewportProvider>
                </HashRouter>
            </AuthContextProvider>
        </AppContextProvider>
    </React.StrictMode>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
