import { useContext, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import AuthContext from "../../contexts/AuthContext";
import classes from "./ConfirmEmailSigninPage.module.css";

const ConfirmEmailSigninPage = () => {
    const navigate = useNavigate();
    const { authState, confirmEmailSignin, signout } = useContext(AuthContext);

    // If the user is already logged in, redirect to the dashboard
    useEffect(() => {
        if (authState === 2) navigate("/");
    }, [authState, navigate]);

    useEffect(() => {
        // Confirm if user can login after they click the email login link
        const handleConfirmation = async () => {
            if (!(await confirmEmailSignin())) {
                // If login confirmation fails, redirect to the login page
                navigate("/login", {
                    state: {
                        type: "error",
                        message: "Login error: please try logging in again.",
                    },
                });
                await signout();
            }
        };

        // if the user is already logged in, redirect to the dashboard page. (other wise proceed with authentication confirmation)
        handleConfirmation();
    }, [confirmEmailSignin, navigate, signout]);

    return (
        <div className={classes.container}>
            <div className={classes.text}>Confirming Login Status...</div>
        </div>
    );
};

export default ConfirmEmailSigninPage;
