import { useContext } from "react";
import AppContext from "../../contexts/AppContext";
import {
    STREAK_ACHVS,
    POINTS_ACHVS,
    EXPLORATION_ACHVS,
    createCards,
} from "../../utils/achievments";
import classes from "./MobileProgressionPage.module.css";
import CardCarousel from "./CardCarousel";
import BadgeCarousel from "./BadgeCarousel";
import ProgressIcon from "../../assets/white_progress_icon.svg";
import Overlay from "../LoginPage/Overlay";

const MobileProgressionPage = () => {
    const { record: user, isLoading } = useContext(AppContext);

    if (isLoading) return <Overlay />;

    let streakCards = [];
    let pointCards = [];
    let explorationCards = [];

    if (user) {
        streakCards = createCards(STREAK_ACHVS, user, "streak");
        pointCards = createCards(POINTS_ACHVS, user, "point");
        explorationCards = createCards(EXPLORATION_ACHVS, user, "exploration");
    }

    return (
        <>
            <div className={classes.green} />
            <div className={classes.title}>
                <img src={ProgressIcon} alt="progress" />
                <div>Progress</div>
            </div>
            <div className={classes.container}>
                <BadgeCarousel />
                <p>Challenges</p>
                <div className={classes.subtitle}>
                    <p>Streaks</p>
                </div>
                <CardCarousel cards={streakCards} />
                <div className={classes.subtitle}>
                    <p>Leaderboard (Awarded weekly)</p>
                </div>
                <CardCarousel cards={pointCards} />
                <div className={classes.subtitle}>
                    <p>Exploration</p>
                </div>
                {explorationCards.type === "span" ? (
                    explorationCards
                ) : (
                    <CardCarousel cards={explorationCards} />
                )}
            </div>
        </>
    );
};

export default MobileProgressionPage;
