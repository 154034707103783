import React, { useContext, useEffect, useState } from "react";
import AppContext from "../../contexts/AppContext";
import classes from "./MyEvents.module.css";
import Overlay from "../LoginPage/Overlay";
import CalendarIcon from "../../assets/calendar.svg";

const MyEventComponent = () => {
    const { visits, isLoading } = useContext(AppContext);
    const [completedVisits, setCompletedVisits] = useState([]);

    const formatDate = (dateString) => {
        const date = new Date(dateString);
        return date.toLocaleString("en-US", {
            timeZone: "America/New_York",
            year: "2-digit",
            month: "2-digit",
            day: "2-digit",
        });
    };

    const simplifyRole = (role) => {
        return role.replace("Packer/Loader", "Packer");
    };

    useEffect(() => {
        if (!isLoading) {
            const completedVisitsList = visits.filter(
                (visit) => visit.Status === "Completed"
            );

            completedVisitsList.sort((a, b) => {
                const dateA = a["Correct slot time"]
                    ? new Date(a["Correct slot time"])
                    : new Date(0);
                const dateB = b["Correct slot time"]
                    ? new Date(b["Correct slot time"])
                    : new Date(0);
                return dateB - dateA;
            });

            setCompletedVisits(
                completedVisitsList.map((visit) => ({
                    ...visit,
                    "Correct slot time": formatDate(visit["Correct slot time"]),
                    "Formatted Type for Texts": simplifyRole(visit["Formatted Type for Texts"]),
                }))
            );
        }
    }, [isLoading, visits]);

    if (isLoading) return <Overlay />;

    return (
        <>
            <div className={classes.eventHeader}>
                <div className={classes.green} />
                <div className={classes.title}>
                    <img src={CalendarIcon} alt="calendar icon" />
                    <div>My Events</div>
                </div>
            </div>
            <div className={classes.eventsList}>
                {completedVisits.map((visit, index) => (
                    <div key={index} className={classes.eventItem}>
                        <div className={classes.eventDetails}>
                            <div className={classes.eventDate}>
                                {visit["Correct slot time"]}
                            </div>
                            <div className={classes.eventLocation}>
                                {visit["Event Location"]}
                            </div>
                        </div>
                        <div className={classes.eventRole}>
                            {visit["Formatted Type for Texts"]}
                        </div>
                    </div>
                ))}
            </div>
        </>
    );
};

export default MyEventComponent;
