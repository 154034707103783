import { useContext, useEffect, useState } from "react";
import classes from "./BadgeProgression.module.css";
import AppContext from "../../contexts/AppContext";
import Badge from "../Badge/Badge";
import levels from "../../utils/levels";
import Star from "../../assets/star.svg";

const BadgeProgression = () => {
    const { record } = useContext(AppContext);
    const [pointsLeft, setPointsLeft] = useState(null);
    const [percent, setPercent] = useState(100);

    // Calculate points left until next rank and percent complete
    useEffect(() => {
        const points = record["Points"];
        if (points !== undefined) {
            let previousPoints = 0;
            for (const level of levels) {
                if (points < level.points) {
                    setPointsLeft(level.points - points);
                    setPercent(
                        ((points - previousPoints) /
                            (level.points - previousPoints)) *
                            100,
                    );
                    break;
                }
                previousPoints = level.points;
            }
        }
    }, [record]);

    return (
        <div className={classes.container}>
            <div className={classes.progress}>
                <div className={classes.progress_text}>
                    <img src={Star} alt="star" />
                    <p>
                        {pointsLeft === null ? (
                            <b>Max Level!</b>
                        ) : (
                            <>
                                <b>{pointsLeft}</b> until next rank
                            </>
                        )}
                    </p>
                </div>
                <div className={classes.progress_bar}>
                    <div
                        style={{
                            width: `${percent ? `${percent}%` : "0"}`,
                        }}
                        className={classes.completed_bar}
                    />
                </div>
            </div>
            <div className={classes.badge}>
                <Badge height={60} detail={false} showNext={true} />
            </div>
        </div>
    );
};

export default BadgeProgression;
