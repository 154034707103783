import { useContext, useEffect, useState } from "react";
import classes from "./Badge.module.css";
import AppContext from "../../contexts/AppContext";
import levels from "../../utils/levels";

const Badge = ({
    height = 200,
    detail = true,
    showNext = false,
    rank = null,
    opacity = 1,
}) => {
    const { record } = useContext(AppContext);
    const [level, setLevel] = useState(0);
    const [name, setName] = useState("");
    const [image, setImage] = useState("");

    useEffect(() => {
        let points = record["Points"];

        if (rank !== null) {
            setLevel(rank);
            setName(levels[rank - 1].name);
            setImage(levels[rank - 1].src);
        } else if (points !== undefined) {
            if (showNext) {
                points *= 2;
            }
            for (let i = levels.length - 1; i >= 0; i--) {
                const level = levels[i];
                if (points >= level.points) {
                    setLevel(i + 1);
                    setName(level.name);
                    setImage(level.src);
                    break;
                }
            }
        }
    }, [record, detail, showNext, rank]);

    return (
        <div
            className={classes.badge}
            style={{
                height: `${height}px`,
                opacity: opacity,
            }}
        >
            {image && <img src={image} alt="badge" />}
            {detail && <span>Rank {level}</span>}
            {detail && <p>{name}</p>}
        </div>
    );
};

export default Badge;
