import Airtable from "airtable";

const base = new Airtable({
    apiKey: process.env.REACT_APP_AIRTABLE_API_KEY,
}).base(process.env.REACT_APP_AIRTABLE_BASE_ID);

const getLeaderboard = async (search, type, time, filters, setLeaderboard) => {
    const field = type === "name" ? "Full Name" : "Volunteer Groups (text)";
    const filter =
        search.trim().length === 0
            ? ""
            : `FIND(LOWER('${search.trim()}'), LOWER({${field}})) > 0`;
    try {
        const records = await base("🙋🏽Volunteers CRM")
            .select({
                maxRecords: 25,
                fields: Object.values(filters).concat([
                    "Airtable Record ID",
                    "Full Name",
                    "First Name",
                    "Last Name",
                    "Volunteer Groups (text)",
                    "Level",
                ]),
                filterByFormula: filter,
                sort: [{ field: filters[time], direction: "desc" }],
            })
            .all();
        setLeaderboard(records.map((record) => record.fields));
    } catch (err) {
        console.error(err);
    }
};

export default getLeaderboard;
