import { useContext, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import classes from "./MobileDashboard.module.css";
import AppContext from "../../contexts/AppContext";
import AuthContext from "../../contexts/AuthContext";
import Badge from "../Badge/Badge";
import BadgeProgression from "./BadgeProgression";
import Overview from "./Overview";
import RadialProgressBar from "../MapContainer/CurrentView/RadialProgressBar";
import getBoroughCounts from "../../utils/getBoroughCounts";
import getFavoriteLocations from "../../utils/getFavoriteLocations";
import Calendar from "../../assets/calendar.svg";
import HelpCircle from "../../assets/help_circle.svg";
import LightningBolt from "../../assets/lightning_bolt.svg";
import OrangeWhiteMap from "../../assets/orange_white_map.svg";
import People from "../../assets/people.svg";

const MobileDashboard = () => {
    const navigate = useNavigate();
    const ctx = useContext(AppContext);
    const { authState } = useContext(AuthContext);
    const { record, isLoading } = ctx;

    const boroughs = ["Bronx", "Manhattan"];
    const boroughCounts = getBoroughCounts(ctx);

    const radials = boroughs.map((borough) => (
        <div key={borough}>
            <RadialProgressBar
                numerator={boroughCounts[borough][0]}
                denominator={boroughCounts[borough][1]}
                size={80}
            />
            <p className={classes.location}>{borough}</p>
        </div>
    ));

    const [visited, location] = getFavoriteLocations(ctx);

    // If the user is not logged in, redirect to the login page
    useEffect(() => {
        if (!isLoading && authState !== 2) navigate("/login");
    }, [authState, isLoading, navigate]);

    return (
        <div className={classes.container}>
            <div className={classes.background} />
            <div className={classes.overview_container}>
                <p>Welcome back, {record["First Name"]}!</p>
                <Overview />
            </div>
            <div className={classes.badge}>
                <Badge />
            </div>
            <BadgeProgression />
            <a href="https://www.grassrootsgrocery.org/join-us-saturday">
                <button className={classes.event_button}>
                    <img src={Calendar} alt="calendar" />
                    Register for an Event
                </button>
            </a>
            <button className={`${classes.event_button} ${classes.full_width_button}`} onClick={() => navigate("/my-events")}>
                <img src={Calendar} alt="calendar" />
                My Events
            </button>
            <div className={classes.buttons}>
                <button onClick={() => navigate("/progression")}>
                    <img src={LightningBolt} alt="lightning_bolt" />
                    Challenges
                </button>
                <button onClick={() => navigate("/leaderboard")}>
                    <img src={People} alt="people" />
                    Leaderboard
                </button>
            </div>
            <div className={classes.visited}>
                <p>Visited Locations</p>
                <div className={classes.radial_container}>
                    <div>
                        <RadialProgressBar
                            numerator={boroughCounts["All"][0]}
                            denominator={boroughCounts["All"][1]}
                            size={80}
                        />
                        <p className={`${classes.location} ${classes.total}`}>
                            Total
                        </p>
                    </div>
                    {radials}
                </div>
                <div className={classes.highlight_container}>
                    <p className={classes.title}>Most Visited</p>
                    <p className={classes.content}>{visited}</p>
                </div>
                <div className={classes.highlight_container}>
                    <p className={classes.title}>Favorite Location</p>
                    <p className={classes.content}>{location}</p>
                </div>
            </div>
            <button
                className={classes.footer_button}
                onClick={() => navigate("/map")}
            >
                <img src={OrangeWhiteMap} alt="help circle" />
                <p>View Progress Map</p>
            </button>
            <button
                className={`${classes.footer_button} ${classes.info}`}
                onClick={() => navigate("/about")}
            >
                <img src={HelpCircle} alt="help circle" />
                <p>
                    What is the Volunteer <br />
                    Engagement Portal?
                </p>
            </button>
        </div>
    );
};

export default MobileDashboard;
