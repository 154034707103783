// Get most visited and favorite location
const getFavoriteLocations = (appContext) => {
    let most_visited = "";
    let favorite_location = "";

    // Get ids of each location
    const ids = appContext.locations.map(
        (location) => location["LocationRecordID"],
    );

    // Get occurences of each location
    let occurences = {};
    if (appContext.record["📍 Drop off location (from 📅 Scheduled Slots)"]) {
        for (const id of appContext.record[
            "📍 Drop off location (from 📅 Scheduled Slots)"
        ]) {
            if (ids.includes(id)) {
                occurences[id] = occurences[id] ? occurences[id] + 1 : 1;
            }
        }
    }

    const high = Math.max.apply(null, Object.values(occurences));
    const high_id = Object.keys(occurences).find(
        (key) => occurences[key] === high,
    );

    let neighborhood_id = "";
    for (const location of appContext.locations) {
        if (
            location["LocationRecordID"] === high_id &&
            location["Neighborhood"]
        ) {
            most_visited = location["Drop off location"];
            neighborhood_id = location["Neighborhood"][0];
        }
    }
    for (const neighborhood of appContext.neighborhoods) {
        if (neighborhood["Calculation"] === neighborhood_id) {
            if (!(neighborhood["Name"])){
                console.log("Neighborhood name field failure");
                continue;
            }
            favorite_location = neighborhood["Name"];
        }
    }

    return [most_visited, favorite_location];
};

export default getFavoriteLocations;
