// Badge levels and point cutoff in order from lowest to highest
const levels = [
    {
        name: "Ambitious Apple",
        points: 0,
        src: `${process.env.PUBLIC_URL}/images/apple_icon.png`,
    },
    {
        name: "Beginner Berry",
        points: 100,
        src: `${process.env.PUBLIC_URL}/images/berry_icon.png`,
    },
    {
        name: "Beaming Broccoli",
        points: 200,
        src: `${process.env.PUBLIC_URL}/images/broccoli_icon.png`,
    },
    {
        name: "Superb Strawberry",
        points: 400,
        src: `${process.env.PUBLIC_URL}/images/strawberry_icon.png`,
    },
    {
        name: "Astute Avocado",
        points: 800,
        src: `${process.env.PUBLIC_URL}/images/avocado_icon.png`,
    },
    {
        name: "Artful Asparagus",
        points: 1600,
        src: `${process.env.PUBLIC_URL}/images/asparagus_icon.png`,
    },
    {
        name: "Brilliant Beet",
        points: 3200,
        src: `${process.env.PUBLIC_URL}/images/beet_icon.png`,
    },
    {
        name: "Commendable Carrot",
        points: 6400,
        src: `${process.env.PUBLIC_URL}/images/carrot_icon.png`,
    },
];

export default levels;
