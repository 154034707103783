import MultiCarousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import classes from "./CardCarousel.module.css";

const responsive = {
    all: {
        breakpoint: { min: 0, max: 10000 },
        items: 1,
    },
};

const CardCarousel = ({ cards = [] }) => {
    const slides = [];
    for (let i = 0; i < cards.length; i += 3) {
        slides.push(
            <div className={classes.slide} key={i}>
                {cards.slice(i, i + 3)}
            </div>,
        );
    }

    return (
        <div className={classes.container}>
            <MultiCarousel
                responsive={responsive}
                transitionDuration={100}
                arrows={false}
                showDots={true}
                renderDotsOutside={true}
                containerClass={classes.carousel}
                itemClass={classes.slide}
            >
                {slides}
            </MultiCarousel>
        </div>
    );
};

export default CardCarousel;
