import classes from "./NavBtn.module.css";
import { useNavigate } from "react-router-dom";

const NavLink = ({
    text = "",
    redirect = "",
    state = undefined,
    font_color = "",
}) => {
    const navigate = useNavigate();

    return (
        <button
            className={classes.btn}
            onClick={() => navigate(redirect, state)}
            style={{ color: font_color }}
        >
            {text}
        </button>
    );
};

export default NavLink;
