import { useContext, useEffect, useState } from "react";
import classes from "./Leaderboard.module.css";
import AppContext from "../../contexts/AppContext";
import Badge from "../Badge/Badge";
import getLeaderboard from "../../utils/getLeaderboard";
import LeaderboardIcon from "../../assets/black_leaderboard_icon.svg";
import SearchIcon from "../../assets/search_icon.svg";
import Star from "../../assets/star.svg";

const timeFilters = {
    "All Time": "Points",
    "Last Month": "Points1Month",
    "Last 2 Weeks": "Points2Weeks",
};

const Leaderboard = () => {
    const { record } = useContext(AppContext);
    const [search, setSearch] = useState("");
    const [type, setType] = useState("name");
    const [time, setTime] = useState("All Time");
    const [leaderboard, setLeaderboard] = useState([]);

    useEffect(() => {
        const timer = setTimeout(() => {
            getLeaderboard(search, type, time, timeFilters, setLeaderboard);
        }, 500);

        return () => clearTimeout(timer);
    }, [search, type, time, setLeaderboard]);

    const timeOptions = Object.keys(timeFilters).map((filter) => (
        <option key={filter} className={filter === time ? classes.active : ""}>
            {filter}
        </option>
    ));

    const rows = leaderboard?.map((row, index) => {
        const groups = row["Volunteer Groups (text)"];
        const highlight =
            row["Airtable Record ID"] === record["Airtable Record ID"]
                ? classes.highlight
                : "";
        return (
            <div key={index} className={classes.row}>
{/*                 <p className={`${classes.rank} ${highlight}`}>{index + 1}</p>
 */}                <div className={classes.badge}>
                    <Badge height={44} detail={false} rank={row["Level"]} />
                </div>
                <p className={`${classes.name} ${highlight}`}>
                    {row["First Name"]} {row["Last Name"][0]}.
                </p>
                <p className={classes.group}>
                    {groups ? groups : "No Affiliation"}
                </p>
                <p className={classes.points}>
                    {time !== "All Time" ? "+" : ""}
                    {row[timeFilters[time]].toLocaleString()}
                </p>
            </div>
        );
    });

    return (
        <div className={classes.container}>
            <div className={classes.title}>
                <img src={LeaderboardIcon} alt="leaderboard icon" />
                <div>Leaderboard</div>
            </div>
            <div className={classes.search_container}>
                <button
                    onClick={() => setType(type === "name" ? "group" : "name")}
                >
                    {type === "name" ? "Search by Name" : "Search by Group"}
                </button>
                <select onChange={(e) => setTime(e.target.value)}>
                    {timeOptions}
                </select>
                <div className={classes.search}>
                    <img src={SearchIcon} alt="search icon" />
                    <input
                        type="text"
                        placeholder={
                            type === "name"
                                ? "Search by name..."
                                : "Search by group..."
                        }
                        onChange={(e) => setSearch(e.target.value)}
                    />
                </div>
            </div>
            <div className={classes.header}>
                <div className={classes.rank} />
                <div className={classes.badge} />
                <div className={classes.name}>Name</div>
                <div className={classes.group}>Group</div>
                <div className={classes.points} />
                <div className={classes.points_header}>
                    <img src={Star} alt="star" />
                    Points
                </div>
            </div>
            {rows}
        </div>
    );
};

export default Leaderboard;
