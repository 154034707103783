import { useEffect, useState } from "react";

import classes from "./Navbar.module.css";
import COLORS from "../../utils/colors";
import NavBtn from "./NavBtn";
import Profile from "./Profile";
import GrassrootsIcon from "../../assets/grassroots_icon.svg";

const Navbar = () => {
    // changes the navbar's color depending on how far down the user has scrolled.
    const [navColor, setNavColor] = useState(COLORS.primary);
    useEffect(() => {
        const handleScroll = () => {
            if (window.scrollY >= 500) {
                setNavColor(COLORS.secondary);
            } else {
                setNavColor(COLORS.primary);
            }
        };

        window.addEventListener("scroll", handleScroll);

        // cleanup function
        return () => {
            window.removeEventListener("scroll", handleScroll);
        };
    }, []);

    return (
        <nav
            className={classes.nav}
            style={{ background: navColor, transition: "all .5s ease" }}
        >
            <div className={classes.left_container}>
                <img src={GrassrootsIcon} alt="logo" />
                <div className={classes.navbtns}>
                    <NavBtn text="Dashboard" redirect="/" />
                    <NavBtn text="Progress" redirect="/progression" />
                    <NavBtn text="About" redirect="/about" />
                </div>
            </div>
            <div className={classes.right_container}>
                <Profile />
            </div>
        </nav>
    );
};

export default Navbar;
