import { useContext, useEffect, useState } from "react";
import classes from "./MobileLeaderboard.module.css";
import AppContext from "../../contexts/AppContext";
import Badge from "../Badge/Badge";
import getLeaderboard from "../../utils/getLeaderboard";
import LeaderboardIcon from "../../assets/large_leaderboard_icon.svg";
import SearchIcon from "../../assets/search_icon.svg";
import CircleUpArrow from "../../assets/circle_up_arrow.svg";
import UpArrow from "../../assets/up_arrow.svg";

const timeFilters = {
    "All Time": "Points",
    "Last Month": "Points1Month",
    "Last 2 Weeks": "Points2Weeks",
};

const MobileLeaderboard = () => {
    const { record } = useContext(AppContext);
    const [backToTop, setbackToTop] = useState(false);
    const [search, setSearch] = useState("");
    const [type, setType] = useState("name");
    const [time, setTime] = useState("All Time");
    const [leaderboard, setLeaderboard] = useState([]);

    useEffect(() => {
        const timer = setTimeout(() => {
            getLeaderboard(search, type, time, timeFilters, setLeaderboard);
        }, 500);

        return () => clearTimeout(timer);
    }, [search, type, time, setLeaderboard]);

    useEffect(() => {
        const handleScroll = () => {
            if (window.scrollY >= 500) {
                setbackToTop(true);
            } else {
                setbackToTop(false);
            }
        };

        window.addEventListener("scroll", handleScroll);

        // cleanup function
        return () => {
            window.removeEventListener("scroll", handleScroll);
        };
    }, []);

    const scrollToTop = () => {
        window.scrollTo({
            top: 0,
            behavior: "smooth",
        });
    };

    const timeOptions = Object.keys(timeFilters).map((filter) => (
        <option key={filter} className={filter === time ? classes.active : ""}>
            {filter}
        </option>
    ));

    const rows = leaderboard?.map((row, index) => {
        const groups = row["Volunteer Groups (text)"];
        const highlight =
            row["Airtable Record ID"] === record["Airtable Record ID"]
                ? classes.highlight
                : "";
        return (
            <div key={index} className={classes.row}>
                {/*                 <p className={`${classes.rank} ${highlight}`}>{index + 1}</p>
 */}                <div className={classes.badge}>
                    <Badge height={44} detail={false} rank={row["Level"]} />
                </div>
                <div className={classes.text}>
                    <p className={highlight}>{row["First Name"]} {row["Last Name"][0]}.</p>
                    <div>
                        <p>{groups ? groups : "No Affiliation"}</p>
                        <p>
                            {time !== "All Time" ? "+" : ""}
                            {row[timeFilters[time]].toLocaleString()}
                        </p>
                    </div>
                </div>
            </div>
        );
    });

    return (
        <div className={classes.container}>
            <div className={classes.green} />
            <div className={classes.title}>
                <img src={LeaderboardIcon} alt="leaderboard icon" />
                <div>Leaderboard</div>
            </div>
            <div className={classes.search_container}>
                <div className={classes.search}>
                    <img src={SearchIcon} alt="search icon" />
                    <input
                        type="text"
                        placeholder={
                            type === "name"
                                ? "Search by name..."
                                : "Search by group..."
                        }
                        onChange={(e) => setSearch(e.target.value)}
                        spellCheck="false"
                    />
                </div>
                <div className={classes.filters}>
                    <button
                        onClick={() =>
                            setType(type === "name" ? "group" : "name")
                        }
                    >
                        {type === "name" ? "Search by Name" : "Search by Group"}
                    </button>
                    <select onChange={(e) => setTime(e.target.value)}>
                        {timeOptions}
                    </select>
                </div>
            </div>
            {rows}
            <div className={classes.back_to_top_bottom} onClick={scrollToTop}>
                <p>Back to top</p>
                <img src={UpArrow} alt="up arrow" />
            </div>
            {backToTop && (
                <div onClick={scrollToTop} className={classes.back_to_top}>
                    <img src={CircleUpArrow} alt="up arrow" />
                </div>
            )}
        </div>
    );
};

export default MobileLeaderboard;
