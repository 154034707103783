import { useContext } from "react";
import classes from "./CurrentView.module.css";
import AppContext from "../../../contexts/AppContext";
import RadialProgressBar from "./RadialProgressBar";
import getBoroughCounts from "../../../utils/getBoroughCounts";

const CurrentView = ({ currentView }) => {
    const ctx = useContext(AppContext);
    const boroughs = ["Bronx", "Manhattan"];
    const boroughCounts = getBoroughCounts(ctx);

    const radials = boroughs.map((borough) => (
        <div key={borough}>
            <RadialProgressBar
                numerator={boroughCounts[borough][0]}
                denominator={boroughCounts[borough][1]}
                size={70}
            />
            <p className={classes.progress_label}>{borough}</p>
        </div>
    ));

    return (
        <div className={classes.container}>
            {Object.keys(currentView).length === 0 ? (
                <p className={classes.placeholder}>Select a marker below.</p>
            ) : (
                <>
                    <div className={classes.text_container}>
                        <p className={classes.label}>Currently Viewing</p>
                        <p className={classes.location}>
                            {currentView.borough}
                        </p>
                    </div>
                    <div className={classes.total_progression}>
                        <RadialProgressBar
                            numerator={boroughCounts["All"][0]}
                            denominator={boroughCounts["All"][1]}
                            size={100}
                            fontSize={22}
                            strokeWidthInner={6}
                            strokeWidthOuter={12}
                        />
                    </div>
                    <div className={classes.detailed_progression}>
                        {radials}
                    </div>
                </>
            )}
        </div>
    );
};

export default CurrentView;
