import {
    createContext,
    useCallback,
    useEffect,
    useState,
    useContext,
} from "react";
import { initializeApp } from "firebase/app";
import {
    getAuth,
    isSignInWithEmailLink,
    sendSignInLinkToEmail,
    signInWithEmailLink,
    signInWithPhoneNumber,
    signOut,
    RecaptchaVerifier,
} from "firebase/auth";
import AppContext from "./AppContext";

const FIREBASE_CONFIG = {
  apiKey: process.env.REACT_APP_API_KEY,
  authDomain: process.env.REACT_APP_AUTH_DOMAIN,
  projectId: process.env.REACT_APP_PROJECT_ID,
  storageBucket: process.env.REACT_APP_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_APP_ID,
  measurementId: process.env.REACT_APP_MEASUREMENT_ID,
};

// Required by firebase to be able to send signin emails.
const actionCodeSettings = {
    // URL you want to redirect back to. The domain (www.example.com) for this
    // URL must be in the authorized domains list in the Firebase Console.
    url:
        process.env.NODE_ENV === "production"
            ? `https://${process.env.REACT_APP_AUTH_DOMAIN}/confirmEmailSignin`
            : "http://localhost:3000/confirmEmailSignin",
    // This must be true.
    handleCodeInApp: true,
};

// the default values of properties that are exposed by the context.
const defaultValues = {
    // 0: not authenticated, 1: no user found, 2: logged in
    authState: 0,
    signinEmail: async () => ({}),
    signinPhone: async () => ({}),
    confirmEmailSignin: async () => ({}),
    confirmPhoneSignIn: async () => ({}),
    signout: async () => ({}),
};

const AuthContext = createContext(defaultValues);

export const AuthContextProvider = (props) => {
    // Firebase initializations
    const app = initializeApp(FIREBASE_CONFIG);
    const auth = getAuth(app);

    const [authState, setAuthState] = useState(defaultValues.authState);
    const { fetchData, setIsLoading } = useContext(AppContext);

    // Maintain authentication state across page refreshes
    useEffect(() => {
        const unsubscribe = auth.onAuthStateChanged(async (user) => {
            if (user === null) {
                setAuthState(0);
                setIsLoading(false);
            } else {
                // Fetch data from Airtable
                fetchData(user).then((result) => {
                    setAuthState(result ? 2 : 1);
                });
            }
        });

        // return a cleanup function.
        return unsubscribe;
    }, [app, auth, fetchData, setIsLoading]);

    const signinEmail = useCallback(
        async (email) => {
            return await sendSignInLinkToEmail(auth, email, actionCodeSettings)
                .then(() => {
                    // The link was successfully sent. Inform the user.
                    // Save the email locally so you don't need to ask the user
                    // for it again if they open the link on the same device.
                    window.localStorage.setItem("emailForSignIn", email);
                    return true;
                })
                .catch((err) => {
                    console.error(err);
                    return false;
                });
        },
        [auth],
    );

    // Send SMS code to phone
    const signinPhone = useCallback(
        async (phone) => {
            if (window.recaptcha === undefined) {
                // Invisible reCAPTCHA verifier
                window.recaptcha = new RecaptchaVerifier(
                    "recaptcha-anchor-btn",
                    { size: "invisible" },
                    auth,
                );
            }

            return await signInWithPhoneNumber(auth, phone, window.recaptcha)
                .then((confirmationResult) => {
                    // SMS sent. Prompt user to type the code from the message, then sign the
                    // user in with confirmationResult.confirm(code).
                    window.confirmationResult = confirmationResult;
                    return true;
                })
                .catch((err) => {
                    console.error(err);
                    // Reset the reCAPTCHA if there is an error
                    window.recaptcha.render().then((id) => {
                        window.grecaptcha.reset(id);
                    });
                    return false;
                });
        },
        [auth],
    );

    // Confirm user is allowed to login with the link
    const confirmEmailSignin = useCallback(async () => {
        if (!isSignInWithEmailLink(auth, window.location.href)) {
            return false;
        }

        // Get the email if available. This should be available if the user
        // completes the flow on the same device where they started it
        let email = window.localStorage.getItem("emailForSignIn");
        if (!email) {
            // User opened the link on a different device. To prevent session
            // fixation attacks, prompt user for the associated email again
            email = window.prompt("Please provide your email for confirmation");
        }

        // The client SDK will parse the code from the link for you.
        return await signInWithEmailLink(auth, email, window.location.href)
            .then((result) => {
                // Remove email from storage and set user
                window.localStorage.removeItem("emailForSignIn");

                // Fetch data from Airtable
                fetchData(result.user).then((resp) => {
                    setAuthState(resp ? 2 : 1);
                });

                return true;
            })
            .catch((err) => {
                console.error(err);
                setAuthState(0);
                return false;
            });
    }, [auth, fetchData]);

    // Confirm that the user has the correct code
    const confirmPhoneSignIn = useCallback(
        async (code) => {
            if (window.confirmationResult === undefined) return false;

            return window.confirmationResult
                .confirm(code)
                .then((result) => {
                    // Fetch data from Airtable
                    fetchData(result.user).then((resp) => {
                        setAuthState(resp ? 2 : 1);
                    });

                    return true;
                })
                .catch((err) => {
                    console.error(err);
                    setAuthState(0);
                    return false;
                });
        },
        [fetchData],
    );

    // Sign out
    const signout = useCallback(async () => {
        setAuthState(0);
        return await signOut(auth)
            .then(() => {
                return true;
            })
            .catch((err) => {
                console.error(err);
                return false;
            });
    }, [auth]);

    return (
        <AuthContext.Provider
            value={{
                authState,
                signinEmail,
                signinPhone,
                confirmEmailSignin,
                confirmPhoneSignIn,
                signout,
            }}
        >
            {props.children}
        </AuthContext.Provider>
    );
};

export default AuthContext;
