import { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { PatternFormat } from "react-number-format";
import classes from "./LoginPage.module.css";
import AppContext from "../../contexts/AppContext";
import AuthContext from "../../contexts/AuthContext";
import GrassrootsLogo from "../../assets/grassroots_logo.svg";
import LoginTrim from "../../assets/login_trim.svg";

const LoginPage = () => {
    const navigate = useNavigate();
    const { isLoading } = useContext(AppContext);
    const { authState, signinPhone } = useContext(AuthContext);

    // signinType: "email" | "phone"
    const [signinType, setSigninType] = useState("phone");
    const [inputValue, setInputValue] = useState("");
    const [isSending, setIsSending] = useState(false);
    const [isInvalidInput, setIsInvalidInput] = useState(false);
    const [height, setHeight] = useState(window.innerHeight);

    // If the user is already logged in, redirect to the dashboard
    useEffect(() => {
        if (!isLoading && authState === 2) navigate("/");
    }, [authState, isLoading, navigate]);

    // Clear recaptcha when page is loaded
    useEffect(() => {
        window.recaptcha = undefined;
    }, []);

    const handleSubmit = async () => {
        if (isSending) return;

        setIsSending(true);
        const input = inputValue.trim();

        const phone = input.match(/\d/g)?.join("");

        if (phone && phone.length === 10) {
            setSigninType("phone");
            if (phone && (await signinPhone("+1" + phone))) {
                window.recaptcha = undefined;
                navigate("/verifyPhoneSignin", { state: { phone: phone } });
            } else {
                setIsInvalidInput(true);
            }
        } else {
            setIsInvalidInput(true);
        }

        setIsSending(false);
    };

    useEffect(() => {
        const handleResize = () => {
            setHeight(window.innerHeight);
        };
        window.addEventListener("resize", handleResize);
        return () => {
            window.removeEventListener("resize", handleResize);
        };
    }, []);

    return (
        <div className={classes.container} style={{ height: `${height}px` }}>
            <div className={classes.card}>
                <img src={GrassrootsLogo} alt="logo" />
                <p className={classes.title}>Log In</p>
                <div className={classes.input_container}>
                    <PatternFormat
                        format="(###) ###-####"
                        mask="_"
                        value={inputValue}
                        placeholder="Enter your phone number..."
                        className={isInvalidInput ? classes.input_error : ""}
                        name="input"
                        onChange={(e) => {
                            setInputValue(e.target.value);
                            if (isInvalidInput) setIsInvalidInput(false);
                        }}
                        onBlur={() => window.scrollTo(0, 10000)}
                    />
                    {isInvalidInput && (
                        <p>
                            The&nbsp;
                            {signinType === "email"
                                ? "email address"
                                : "phone number"}
                            &nbsp;you entered is invalid.
                        </p>
                    )}
                </div>
                <button
                    id="recaptcha-anchor-btn"
                    onClick={handleSubmit}
                    className={isSending ? classes.sending : ""}
                >
                    {isSending ? "Sending..." : "Send Confirmation"}
                </button>
            </div>
            <img src={LoginTrim} alt="footer" />
        </div>
    );
};

export default LoginPage;