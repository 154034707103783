import { useContext, useEffect, useState, useRef } from "react";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import classes from "./BadgeCarousel.module.css";
import AppContext from "../../contexts/AppContext";
import Badge from "../Badge/Badge";
import levels from "../../utils/levels";

const responsive = {
    small: {
        breakpoint: { min: 0, max: 800 },
        items: 1,
    },
    large: {
        breakpoint: { min: 801, max: 10000 },
        items: 3,
    },
};

const BadgeCarousel = () => {
    const ref = useRef(null);
    const { record } = useContext(AppContext);
    const [viewAll, setViewAll] = useState(false);
    const [small, setSmall] = useState(false);

    // Start at the user's current badge
    useEffect(() => {
        if (ref && ref.current) {
            if (small) {
                ref.current.goToSlide(record["Level"] - 1);
            } else {
                let level = record["Level"] - 2;
                if (level < 0) level = 0;
                if (level > levels.length - 3) level = levels.length - 3;
                ref.current.goToSlide(level);
            }
        }
    }, [record, small]);

    useEffect(() => {
        updateDimensions();
        window.addEventListener("resize", updateDimensions);

        return () => window.removeEventListener("resize", updateDimensions);
    }, []);

    const updateDimensions = () => {
        const width = window.innerWidth;
        setSmall(width <= 800);
    };

    const badges = levels.map((level, index) => (
        <Badge
            key={index}
            height={160}
            rank={index + 1}
            opacity={level.points <= record["Points"] ? 1 : 0.5}
        />
    ));

    return (
        <div className={classes.container}>
            <button onClick={() => setViewAll(!viewAll)}>
                {viewAll ? "Hide All" : "View All"}
            </button>
            {viewAll && (
                <div className={classes.badges}>
                    <div className={classes.small}>{badges}</div>
                    <div className={classes.large}>{badges.slice(0, 4)}</div>
                    <div className={classes.large}>{badges.slice(4)}</div>
                </div>
            )}
            {!viewAll && (
                <Carousel
                    ref={ref}
                    responsive={responsive}
                    transitionDuration={100}
                    containerClass={
                        small ? classes.carousel_small : classes.carousel_large
                    }
                >
                    {badges}
                </Carousel>
            )}
        </div>
    );
};

export default BadgeCarousel;
