import { useEffect, useContext, useState } from "react";
import { useNavigate } from "react-router-dom";
import classes from "./MobileMapContainer.module.css";
import AppContext from "../../contexts/AppContext";
import AuthContext from "../../contexts/AuthContext";
import Map from "./Map/Map";
import MobileCurrentView from "./CurrentView/MobileCurrentView";
import xButton from "../../assets/x_button.svg";

const MobileMapContainer = () => {
    const navigate = useNavigate();
    const { isLoading } = useContext(AppContext);
    const { authState } = useContext(AuthContext);
    // filter: "visited" | "unvisited" | "both"
    const [filter, setFilter] = useState("both");
    const [currentView, setCurrentView] = useState({});

    // If the user is not logged in, redirect to the login page
    useEffect(() => {
        if (!isLoading && authState !== 2) navigate("/login");
    }, [authState, isLoading, navigate]);

    // Prevent scrolling and zooming
    useEffect(() => {
        window.scrollTo(0, 0);

        const preventScroll = (e) => e.preventDefault();
        document.addEventListener("touchmove", preventScroll, {
            passive: false,
        });
        return () => {
            document.removeEventListener("touchmove", preventScroll);
        };
    }, []);

    return (
        <div className={classes.container}>
            <div className={classes.buttons}>
                <button id={classes.exit} onClick={() => navigate("/")}>
                    <img src={xButton} alt="" />
                </button>
                <button
                    className={filter === "visited" ? classes.selected : ""}
                    onClick={() => setFilter("visited")}
                >
                    Visited
                </button>
                <button
                    className={filter === "unvisited" ? classes.selected : ""}
                    onClick={() => setFilter("unvisited")}
                >
                    Unvisited
                </button>
                <button
                    className={filter === "both" ? classes.selected : ""}
                    onClick={() => setFilter("both")}
                >
                    Both
                </button>
            </div>
            <MobileCurrentView
                currentView={currentView}
                setCurrentView={setCurrentView}
                bour
            />
            <Map
                currentView={currentView}
                setCurrentView={setCurrentView}
                filter={filter}
            />
        </div>
    );
};

export default MobileMapContainer;
